(function($){

    /*
     * Fix to clear form entry after clicking the try-again-button
     */
    $('.cp-form').on('click', '.cp-try-again a', function(){
        var $this = $(this);
        var moduleElements = $this.closest('.cp-module-content').find('.module-elements');

        // remove user input of this question
        // caution: we cant use form reset() here!
        moduleElements.find('input[type="text"], textfield')
            .val('');

        moduleElements.find('input, select')
            .removeAttr('checked')
            .removeAttr('selected');
    });

}(jQuery));
