(function($){

	"use strict";

  var wiki_topics = $("[data-wiki-expander]");
  var TOPIC_LIMIT = 5;

  wiki_topics.each(function(){
    var wrapper = $(this);
    var lists = wrapper.find(".topic-lists-list");

    lists.each(function(){
      var list = $(this);
      var trigger = list.find(".show-more");
      var body = list.find("ul");
      var items = body.find("li");
      
      if(items.length <= TOPIC_LIMIT) {
        trigger.hide();
      } else {
        var full_h = body.outerHeight(true);
        var item_h = items.eq(0).outerHeight(true);
        var closed_h = TOPIC_LIMIT * item_h;
        var is_open = false;


        body.css("height", closed_h);

        trigger.click(function(e){
          e.preventDefault();

          if(is_open) {
            body.css("height", closed_h);
          } else {
            body.css("height", full_h);
          }
          
          is_open = !is_open;
        });
      }
    });
  });


  $(".wiki-documents-nav-items").find("a.btn").each(function(){
    $(this).click(function(e){
      e.preventDefault();
      var href = $(this).attr("href");
      var target = $(href);

      $('html, body').animate({
        scrollTop: target.offset().top - 50,
      }, 600);
    });
  });

})(jQuery);
