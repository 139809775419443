/**
 * Created by dkugelmann on 05.04.2017.
 */

(function($){
  $('[data-toggle="tooltip"]').tooltip();

  $("a[href^='#'].btn").each(function(){
    $(this).click(function(e){
      e.preventDefault();
      var href = $(this).attr("href");
      var target = $(href);console.log(target);

      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - 50,
        }, 600);
      }

    });
  });

  var sofunktionierts_content_slider = $(".sofunktionierts-content-slider").flickity({
    cellSelector: ".slide",
    pageDots: false,
    prevNextButtons: false,
    resize: true,
    wrapAround: false,
    asNavFor: '.sofunktionierts-slider',
    selectedAttraction: 0.2,
    friction: 0.8,
    draggable: false
  });



  var sofunktionierts_slider = $(".sofunktionierts-slider").flickity({
    cellSelector: ".slide",
    prevNextButtons: false,
    resize: true,
    wrapAround: false,
    //asNavFor: '.sofunktionierts-content-slider',
  });

  sofunktionierts_content_slider.on( 'select.flickity', function() {
    var newSlideNr = $(this).data('flickity').selectedIndex + 1;
    $('#sofunktionierts .steps li').removeClass('active');
    $('#sofunktionierts .steps li:nth-child('+ newSlideNr +')').addClass('active');
  });

  $('#sofunktionierts .steps li').on( 'click', function() {
    var index = $(this).index();
    sofunktionierts_slider.flickity( 'select', index );
  });

  $("#unit-knob").knob({
    min:0,
    max:100,
    width: 60,
    height: 60,
    fgColor:"#ed008c",
    format: function (v) { return v + "%"; }
  });

  // Open extenal forum content links in new tab
  $('#bbpress-forums li.bbp-body div.bbp-reply-content a[href^=http]').click(function(e) {
		// window.open($(this).attr('href'), '_blank');
    if (location.hostname.replace(/^www\./, '') !== this.hostname.replace(/^www\./, '')) {
			window.open($(this).attr('href'), '_blank');
      // stop default and other behaviour
      e.preventDefault();
      e.stopImmediatePropagation();
    }
	})

})(jQuery);
