(function($){

	"use strict";

    var gallery = $("#opinions-slider");
    
    gallery.flickity({
      cellSelector: ".opinions-slider-slide",
      prevNextButtons: true,
      autoPlay: 6000,
      resize: true,
      wrapAround: true,
      arrowShape: { 
        x0: 10,
        x1: 60, y1: 50,
        x2: 65, y2: 45,
        x3: 20
      }
    });

})(jQuery);