(function($){

    "use strict";

    $(window).scroll(function(){
        if($('.scroll-up').length){
            if($(document).scrollTop() >=500){
                $('.scroll-up').css('opacity', 1.0);
            } else{
                $('.scroll-up').css('opacity', 0.0);
            }
        }

    });

    $(function() {
        $('a[href*="#"]:not([href="#"]):not([href*="#tabs"]):not([href*="#lightbox"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 30
                    }, 400);
                    // return false;
                }
            }
        });
    });

})(jQuery);

