(function($){

	"use strict";
  
  /*
   *  MENU BAR FIXING AND RESIZING
   */

  var bar = $("header#masthead");
  var repl = $(".avon-fixed-header-replacer");
  var scroll_happened = false;
  var isStuck = false;
  var THRESHOLD = 100;

  var resize_bar = function() {
      if ($(window).scrollTop() > THRESHOLD) {
        bar.addClass("mini");
        // repl.addClass("mini");
        isStuck = true;
      } else {
        bar.removeClass("mini");
        // repl.removeClass("mini");
        isStuck = false;
      }

  };

  $(window).scroll(resize_bar);

  $(window).resize(resize_bar);
  resize_bar();

})(jQuery);
